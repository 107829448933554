import React, { useEffect, useState } from 'react';
import jsQR from 'jsqr';

const List = () => {
    const streamingUrl = 'http://192.168.0.8:81/stream';
    const [qrCodeData, setQrCodeData] = useState<string | null>(null);

    useEffect(() => {
        const img = document.getElementById('streaming-img') as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d', { willReadFrequently: true });

        const scanQRCode = () => {
            if (img && ctx && img.width > 0 && img.height > 0) { // 이미지 크기가 0이 아닌지 확인 // 페이지 전환시 에러핸들링용
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code) {
                    setQrCodeData(code.data);
                }
            }
            requestAnimationFrame(scanQRCode);
        };

        if (img) {
            img.onload = () => {
                scanQRCode();
            };
            img.src = streamingUrl;
        }

        return () => {
            if (img) {
                img.src = '';
            }
        };
    }, [streamingUrl]);

    return (
        <div>
            <h1>ESP32-CAM 스트리밍</h1>
            <img id="streaming-img" crossOrigin="anonymous" alt="ESP32-CAM 스트리밍" />
            
             <p>QR 코드 내용: {qrCodeData}</p>
        </div>
    );
};

export default List;
