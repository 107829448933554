import { useState } from "react";
import OptionList from "./OptionList";
import OptionCreate from "./OptionCreate";


const Option = () => {

    const [optionMenu, setOptionMenu] = useState<boolean>(true);
    function selectOptionMenu() {
        if (optionMenu) {
            setOptionMenu(false);
        } else {
            setOptionMenu(true);

        }
    }
    
    return (
        <div className="Option">
            <section className='optionChoice'>
                <button className={optionMenu ? "optionList": ""} onClick={selectOptionMenu}>옵션리스트</button>
                <button className={optionMenu ? "": "optionCreate"} onClick={selectOptionMenu}>옵션추가</button>
            </section>
            {optionMenu ? <OptionList /> : <OptionCreate />}
        </div>
    );
};

export default Option;