// recoil.tsx
import { atom } from 'recoil';

export const branchCodeState = atom<string | null>({
    key: 'branchCodeState',
    default: '',
});

export const kioskNumberState = atom<string | null>({
    key: 'kioskNumberState',
    default: '',
});
