import { useEffect, useState } from "react";
import axios from "axios";

const OptionCreate = () => {
    const [createOptionName, setCreateOptionName] = useState<string>('');
    const [createOptionType, setCreateOptionType] = useState<string>('');
    const [createOptionItemName, setCreateOptionItemName] = useState<string>('');
    const [createOptionItemPrice, setCreateOptionItemPrice] = useState<number>(0);

    const [optionList, setOptionList] = useState<any[]>([]);
    const [optionItems, setOptionItems] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>({});
    const [addOtionItem, setAddOptionItem] = useState<boolean>(false);
    async function createOption() {
        try {
            console.log(createOptionName, createOptionType);
            const response = await axios.post(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/option/createOption`, {
                name: createOptionName,
                type: createOptionType
            });
            console.log(response);
            if (response.status === 201) {
                alert('옵션 추가 완료');
                window.location.reload();
            }
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 409) {
                alert('중복된 옵션명이 있습니다.');
            }
        }
    }
    async function createOptionItem() {
        try {
            const response = await axios.post(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/option/createOptionItem`, {
                name: createOptionItemName,
                price: createOptionItemPrice,
                option_id: selectedOption.id
            });
            console.log(response);
            if (response.status === 201) {
                alert('옵션항목 추가 완료');
                // window.location.reload();
            }
        } catch (error: any) {
            console.log(error);
            if (error.response.status === 409) {
                alert('중복된 옵션명이 있습니다.');
            }
        }
    }

    async function getOptionList() {
        try {
            const response = await axios.get(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/option/getOptionList`);
            if (response.status === 200) {
                console.log(response.data);
                setOptionList(response.data);
                if (response.data.length > 0) {
                    setSelectedOption(response.data[0]);
                }

            }
        } catch (error: any) {
            console.log(error);
        }

    }
    async function getOptionItemList() {
        try {
            const response = await axios.get(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/option/getOptionItemList/${selectedOption.id}`);
            if (response.status === 200) {
                console.log(response.data);
                setOptionItems(response.data);
            }
        } catch (error: any) {
            console.log(error);
        }
    }


    useEffect(() => {
        getOptionList();
    }, []);
    useEffect(() => {
        getOptionItemList();
    }, [selectedOption]);

    return (
        <div className='OptionCreate'>
            <section className="create">
                <h1>옵션 추가</h1>
                <article>
                    <div>옵션명</div>
                    <div>
                        <input
                            type="text"
                            name="createOptionName"
                            placeholder='옵션명을 입력해주세요'
                            value={createOptionName}
                            onChange={(e) => setCreateOptionName(e.target.value)}
                        />
                    </div>
                </article>
                <article>
                    <div>옵션타입</div>
                    <div>
                        <input
                            type="text"
                            name="createOptionType"
                            placeholder='옵션타입을 입력해주세요'
                            value={createOptionType}
                            onChange={(e) => setCreateOptionType(e.target.value)}
                        />
                    </div>
                </article>
                <div className='createButton'>
                    <button onClick={createOption}>옵션 추가</button>
                </div>
            </section>

            <section className="list">
                <h1>옵션 리스트</h1>
                <article>
                    <div>옵션명</div>
                    <div className="optionListContainer">
                        {optionList.map((option, index) => {
                            return (
                                <button
                                    key={index}
                                    className={selectedOption.name == option.name ? "optionSelect" : ""}
                                    onClick={() => setSelectedOption(option)}
                                >
                                    {option.name}
                                </button>
                            );
                        })}
                    </div>
                </article>
                <article>
                    <div>타입</div>
                    <div>{selectedOption.type}</div>
                </article>
                <article>
                    <div>옵션 항목</div>
                    <div className="optionItemsContainer">
                        {optionItems.map((optionItem, index) => {
                            return (
                                <div 
                                    key={index}
                                    className="optionItems"
                                >
                                    <p>{optionItem.name}</p>
                                    <p>{optionItem.price}원</p>
                                </div>
                            );
                        })}
                    </div>
                </article>
            </section>


            <section>
                <h1>옵션항목 추가</h1>
                <article>
                    <div>선택된 옵션</div>
                    <div>{selectedOption.name}</div>
                </article>
                <article>
                    <div>옵션항목</div>
                    <div>
                        <input
                            type="text"
                            name="createOptionItemName"
                            placeholder="옵션의 항목명을 입력해주세요"
                            value={createOptionItemName}
                            onChange={(e) => setCreateOptionItemName(e.target.value)}
                        />
                    </div>
                </article>
                <article>
                    <div>가격</div>
                    <div>
                        <input
                            type="number"
                            name="createOptionItemPrice"
                            placeholder="옵션의 가격을 입력해주세요"
                            value={createOptionItemPrice}
                            onChange={(e) => setCreateOptionItemPrice(Number(e.target.value))}
                        />
                    </div>
                </article>
                <div className='createButton'>
                    <button onClick={createOptionItem}>항목 추가</button>
                </div>
            </section>
        </div>
    );
};

export default OptionCreate;
