import React, { useEffect } from 'react';
import { useState } from 'react';
const WebSocketComponent = () => {
const [webSocketChat, setWebSocketChat] = useState<string>('');

  useEffect(() => {
    // 웹소켓 연결
    const socket = new WebSocket('ws://192.168.0.254:8083');

    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.onmessage = (event) => {
      console.log('Received message from WebSocket:', event.data);
      setWebSocketChat(event.data);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    socket.onerror = (error) => {
      console.log('WebSocket error:', error);
    };

    // 컴포넌트가 언마운트될 때 웹소켓 연결을 닫음
    return () => {
      socket.close();
    };
  }, []);

  return (
    <div>
      <h1>WebSocket Communication</h1>
      <h1>{webSocketChat}</h1>
    </div>
  );
};

export default WebSocketComponent;
