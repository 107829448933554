import React, { useState, useEffect, useRef } from 'react';
interface UserInteracted {
    userInteracted?: boolean;
}

const WebSocketVoiceStreaming = ({ userInteracted }: UserInteracted) => {
    const [isRecording, setIsRecording] = useState(true);
    const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
    const [scriptProcessor, setScriptProcessor] = useState<ScriptProcessorNode | null>(null);
    const [requestStatus, setRequestStatus] = useState<boolean>(true);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const [check, setCheck] = useState<any>("");


    let animationFrameId: any;




    useEffect(() => {
        if (userInteracted) {
            console.log("!!!!");
            const webSocket = new WebSocket("wss://nanonix.lol/VoiceOrder");

            webSocket.onopen = () => console.log("WebSocket connected");
            webSocket.onerror = (error) => console.error("WebSocket error:", error);
            webSocket.onmessage = (event) => {
                setCheck(event.data.toString());
                if (typeof event.data === 'string') {
                    console.log(JSON.parse(event.data).complateSign);
                    if (JSON.parse(event.data).complateSign === true) {
                        setRequestStatus(false);
                        setIsRecording(false);
                        scriptProcessor?.disconnect();
                        audioContext?.close();
                        webSocket?.close();
                        setAudioContext(null);
                        setScriptProcessor(null);
                        cancelAnimationFrame(animationFrameId);
                        alert("request complate");
                    } else {
                        console.log("다시 request");
                        setRequestStatus(true);
                    }
                } else {
                    try {
                        console.log('Received message from server:', event.data);
                        setIsRecording(false);
                        const audioBlob = new Blob([event.data], { type: 'audio/mpeg' });
                        const audioUrl = URL.createObjectURL(audioBlob);
                        if (audioRef.current) {
                            audioRef.current.src = audioUrl;
                            audioRef.current.play().catch(error => console.error("Playback failed", error));
                        }


                    } catch (e: any) {
                        setCheck(e.toString());
                    }
                }
            }

            const audioCtx = new AudioContext();
            setAudioContext(audioCtx);

            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then(stream => {
                    const source = audioCtx.createMediaStreamSource(stream);
                    const processor = audioCtx.createScriptProcessor();

                    processor.onaudioprocess = (event) => {
                        const input = event.inputBuffer.getChannelData(0);
                        const buffer = new ArrayBuffer(input.length * 2); // 16-bit samples
                        const view = new DataView(buffer);
                        for (let i = 0; i < input.length; i++) {
                            const s = Math.max(-1, Math.min(1, input[i]));
                            view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
                        }
                        if (webSocket.readyState === WebSocket.OPEN && isRecording) {
                            webSocket.send(buffer); // send raw data

                        }
                    };
                    source.connect(processor);
                    processor.connect(audioCtx.destination);
                    setScriptProcessor(processor);

                })
                .catch(error => {
                    console.error("Audio stream error:", error)

                    alert(error);
                });


            const audioEnded = () => {
                console.log("오디오 재생이 끝났습니다.");
                if (webSocket.readyState === WebSocket.OPEN) {
                    webSocket.send("request");
                }
            };
            
            const audioEl = audioRef.current;
            if (audioEl) {
                audioEl.addEventListener('ended', audioEnded);
            }


            return () => {
                setIsRecording(false);
                scriptProcessor?.disconnect();
                audioContext?.close();
                webSocket?.close();
                setAudioContext(null);
                setScriptProcessor(null);
                cancelAnimationFrame(animationFrameId);
                // 컴포넌트가 언마운트되거나 오디오 소스가 변경될 때 리스너를 제거합니다.
                if (audioEl) {
                    audioEl.removeEventListener('ended', audioEnded);
                }
            };
        }
    }, [userInteracted]);


    // const startRecording = () => setIsRecording(true);
    // const stopRecording = () => setIsRecording(false);

    return (
        <div className='WebSocketVoiceStreaming'>
            {check}
            <div>
                <audio ref={audioRef} controls />
            </div>
        </div>
    );
};

export default WebSocketVoiceStreaming;
