import { Route, Routes, Link } from 'react-router-dom';
import Setting from './Setting/Setting';
import './App.css';
import { useEffect, useState } from 'react';
import TopBar from './Kiosk/TopBar';
import WebSocket from './Test/WebSocket';


function App() {
  const [branchCode, setBranchCode] = useState<string | null>(null);
  const [kioskNumber, setKioskNumber] = useState<string | null>(null);

  useEffect(() => {
    // 초기 설정 로직은 그대로 유지
    let branchCodes = localStorage.getItem('branchCode');
    let kioskNumbers = localStorage.getItem('kioskNumber');
    setBranchCode(branchCodes)
    setKioskNumber(kioskNumbers);
    // default 값이 없을 경우 설정 초기화
    if (!branchCodes || !kioskNumbers) {
      // 잠깐 주석처리
      resetSettings();
    }
  }, []);
  async function resetLocalStorage() {
    localStorage.clear();
    alert('Local Storage has been reset.');
    window.location.reload();
  }

  const resetSettings = () => {
    const branchCodes = prompt('지점코드를 입력해주세요.') || '';
    const kioskNumbers = prompt('키오스크 번호를 입력해주세요.') || '';
    localStorage.setItem('branchCode', branchCodes);
    localStorage.setItem('kioskNumber', kioskNumbers);
    setBranchCode(branchCodes);
    setKioskNumber(kioskNumbers);
  };

  return (
      <section className="App">
        <h1>Setting'-'</h1>
        <Routes>
          <Route path="/" element={<TopBar />} />
          <Route path="/setting/*" element={<Setting />} />
        </Routes>
        <WebSocket></WebSocket>

        {/* <Link to="/setting">
          <h1>설정</h1>
        </Link>  */}
      </section>
  );
}

export default App;
