import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import "./Setting.css";
import Header from './Header/Header';
import List from './List/List';
import Option from './Option/Option';
import Category from './Category/Category';
const Setting = () => {
    return (
        <div className='Setting'>
            <Header />
            <Routes>
                <Route path="/" element={<Navigate to="list" />} />
                <Route path="list" element={<List />} />
                <Route path="option" element={<Option />} />
                <Route path="category" element={<Category />} />
            </Routes>
        </div>
    );
};

export default Setting;