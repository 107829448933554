// TopBar.tsx
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { branchCodeState, kioskNumberState } from '../atoms/recoil';
import WebSocketVoiceStreaming from './WebSocketVoiceStreaming';

const TopBar = () => {
    const [showAI, setShowAI] = useState<boolean>(false);
    const [branchCode, setBranchCode] = useRecoilState<string | null>(branchCodeState);
    const [kioskNumber, setKioskNumber] = useRecoilState<string | null>(kioskNumberState);

    const [userInteracted, setUserInteracted] = useState<boolean>(false);

    useEffect(() => {
        // 초기 설정 로직은 그대로 유지
        let branchCodes = localStorage.getItem('branchCode');
        let kioskNumbers = localStorage.getItem('kioskNumber');
        setBranchCode(branchCodes)
        setKioskNumber(kioskNumbers);
        // default 값이 없을 경우 설정 초기화
        if (!branchCodes || !kioskNumbers) {
            // 잠깐 주석처리
            resetSettings();
        }
    }, []);

    const resetSettings = () => {
        const branchCodes = prompt('지점코드를 입력해주세요.') || '';
        const kioskNumbers = prompt('키오스크 번호를 입력해주세요.') || '';
        localStorage.setItem('branchCode', branchCodes);
        localStorage.setItem('kioskNumber', kioskNumbers);
        setBranchCode(branchCodes);
        setKioskNumber(kioskNumbers);
    };

    let holdTimer: ReturnType<typeof setTimeout>;

    const startTimer = () => {
        holdTimer = setTimeout(() => {
            localStorage.clear();
            resetSettings();
        }, 2000); // 3초 동안 누르고 있어야 함
    };

    const stopTimer = () => {
        clearTimeout(holdTimer); // 타이머 취소
    };

    const handleMouseDown = () => startTimer();
    const handleMouseUp = () => stopTimer();

    const handleTouchStart = () => startTimer();
    const handleTouchEnd = () => stopTimer();

    return (
        <div className='TopBar'>
            <div className='topButton'>
                <div
                    className='branchSetting'
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp} // 마우스가 영역을 벗어나면 타이머 취소
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    {/* 설정 영역 */}
                </div>
                <button
                    className='AIButton'
                    onClick={() => {
                        setShowAI(!showAI);
                        setUserInteracted(true);
                    }}

                >
                    AI 버튼/아이콘
                </button>

            </div>
            {showAI && (
                <div>
                    <WebSocketVoiceStreaming userInteracted={userInteracted} />
                </div>
            )}
        </div>
    );
};

export default TopBar;
