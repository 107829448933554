import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const Header = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    const getLinkClass = (path: string): string =>
        activeLink === path ? 'link link-active' : 'link';

    return (
        <div className='Header'>
            <Link to="/setting/list" className={getLinkClass("/setting/list")}>
                <h1>상품목록</h1>
            </Link>
            <Link to="/setting/store" className={getLinkClass("/setting/store")}>
                <h1>지점정보</h1>
            </Link>
            <Link to="/setting/option" className={getLinkClass("/setting/option")}>
                <h1>옵션관리</h1>
            </Link>
            <Link to="/setting/category" className={getLinkClass("/setting/category")}>
                <h1>카테고리</h1>
            </Link>
            <Link to="/setting/create" className={getLinkClass("/setting/create")}>
                <h1>상품추가</h1>
            </Link>
        </div>
    );
};

export default Header;
