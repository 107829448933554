import { useEffect, useState } from "react";
import axios from "axios";
import { write } from "fs";
const Category = () => {

    const [choice, setChoice] = useState<boolean>(true);
    const [mainCategories, setMainCategories] = useState<MainCategory[]>([]);
    const [subategories, setSubategories] = useState<SubCategory[]>([]);
    const [selectedMainCategory, setSelectedMainCategory] = useState<string>("");
    const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");


    const [writeCategoryName, setWriteCategoryName] = useState<string>('');

    interface MainCategory {
        id: number;
        name: string;
        order_number: number;
    }
    interface SubCategory {
        id: number;
        name: string;
        order_number: number;
        main_category_id: number;
    }
    function selectAddCategory(choice: boolean) {
        if (choice) {
            setChoice(true);
        } else {
            setChoice(false);

        }
    }
    // 메인 카테고리 조회
    async function getMainCategories() {
        try {
            console.log("메인카테고리");
            const response = await axios.get(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/getMainCategories`);
            if (response.status === 200) {
                console.log("!!!!!!!!!!!!!!!!!!!!");
                console.log(response.data);
                setMainCategories(response.data);
                setSelectedMainCategory(response.data[0].name);
            }
        } catch (error) {
            console.error(error);

        }
    }
    // 메인 카테고리 생성
    async function createMainCategory(mainCategoryName: string) {
        if (!mainCategoryName) {
            alert("값을 입력해주세요");
            return;
        }
        try {
            const response = await axios.post(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/createMainCategory`, {
                name: mainCategoryName
            });
            if (response.status === 201) {
                console.log(response.data);
                window.location.reload();
            } else {
                console.error('Unexpected response:', response);
            }

        } catch (error:any) {
            console.error(error);
            if (error.response.data.message === 'Duplicate Error') {
                alert('중복된 옵션명이 있습니다.');
            }
        }
    }


    // 서브 카테고리 조회
    async function getSubCategory(mainCategoryName: string) {
        try {
            console.log("서브카테고리");
            const response = await axios.get(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/getSubCategories/${selectedMainCategory}`);
            if (response.status === 200) {
                console.log(response.data);
                setSubategories(response.data);
                setSelectedSubCategory(response.data[0].name);
            }
        } catch (error) {
            console.error(error);
        }
    }
    // 서브 카테고리 생성
    async function createSubCategory(subCategoryName: string) {
        console.log("서브카테고리 추가");
        if (!selectedMainCategory && !subCategoryName) {
            alert("값을 입력해주세요");
            return;
        }
        try {
            const response = await axios.post(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/createSubCategory`, {
                main_category_id: selectedMainCategory,
                name: subCategoryName
            });
            if (response.status === 201) {
                console.log(response.data);
                getSubCategory(selectedMainCategory);
                // window.location.reload();


            } else {
                console.error('Unexpected response:', response);
            }

        } catch (error:any) {
            console.error(error);
            if (error.response.data.message === 'Duplicate Error') {
                alert('중복된 옵션명이 있습니다.');
            }
        }
    }

    async function deleteMainCategory() {
        console.log(selectedMainCategory);
        try {
            const response = await axios.delete(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/deleteMainCategory`, {
                data: {
                    deleteMainCategoryName: selectedMainCategory
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                window.location.reload();
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error(error);
        }

    }
    async function deleteSubCategory() {
        try {
            const response = await axios.delete(`https://${process.env.REACT_APP_SERVER_URL}/api/setting/category/deleteSubCategory`, {
                data: {
                    deleteSubCategoryName: selectedSubCategory
                }
            });

            if (response.status === 200) {
                console.log(response.data);
                window.location.reload();
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getMainCategories();
    }, [])

    useEffect(() => {
        getSubCategory(selectedMainCategory);
    }, [selectedMainCategory])







    return (
        <div className={`Category`}>
            <section className="createCategoryType">
                <button className={choice ? 'categoryAddSelected' : ''} onClick={() => selectAddCategory(true)}>
                    메인 카테고리 추가
                </button>
                <button className={choice ? '' : 'categoryAddSelected'} onClick={() => selectAddCategory(false)}>
                    서브 카테고리 추가
                </button>
            </section>

            {/* 카테고리 추가 */}
            {choice ?
                <section className="choiceContainer">
                    <h1>메인 카테고리 추가</h1>
                    <article>
                        <div>
                            <p>
                                메인 카테고리명 입력
                            </p>
                        </div>
                        <div>
                            <input
                                placeholder="추가할 메인카테고리명"
                                type="text"
                                value={writeCategoryName}
                                onChange={(e) => setWriteCategoryName(e.target.value)}
                            />
                        </div>
                    </article>
                    <div className="createButton">
                        <button onClick={() => { createMainCategory(writeCategoryName) }}>추가</button>
                    </div>
                </section>
                :
                <section className="choiceContainer">

                    <h1>서브카테고리 추가</h1>
                    <article>
                        <div className="">
                            <p>
                                선택된 메인 카테고리
                            </p>
                        </div>
                        <div>
                            {selectedMainCategory}
                        </div>
                    </article>
                    <article>
                        <div>
                            <p>
                                서브 카테고리명 입력
                            </p>
                        </div>
                        <div>
                            <input
                                placeholder="추가할 서브카테고리명"
                                type="text"
                                value={writeCategoryName}
                                onChange={(e) => setWriteCategoryName(e.target.value)}
                            />
                        </div>
                    </article>
                    <div className="createButton">
                        <button onClick={() => createSubCategory(writeCategoryName)}>추가</button>
                    </div>
                </section>

            }



            {/* 카테고리 목록 보기 */}
            <section className="categoryList">
                <h1>카테고리 리스트</h1>
                <article>
                    <div>
                        <p>메인카테고리</p>
                    </div>
                    <div>
                        {mainCategories.map((item: MainCategory) => (
                            <button
                                className={selectedMainCategory === item.name ? 'selectedMainCategory' : ''}
                                onClick={() => setSelectedMainCategory(item.name)}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                </article>
                <article className="list">
                    <div>
                        <p>서브카테고리</p>
                    </div>
                    <div>
                        {subategories.map((item: SubCategory) => (
                            <button
                                className={selectedSubCategory === item.name ? 'selecetdSubCategory' : ''}
                                onClick={() => setSelectedSubCategory(item.name)}
                            >
                                {item.name}
                            </button>
                        ))}
                    </div>
                </article>
            </section>




            <section className="deleteCategory">
                <h1>카테고리 삭제</h1>
                <article>
                    <div>
                        <p>삭제할(선택된) 메인카테고리</p>
                    </div>
                    <div>
                        <p>{selectedMainCategory}</p>
                        <button onClick={deleteMainCategory}>삭제</button>
                    </div>
                </article>
                <article>
                    <div>
                        <p>삭제할(선택된) 서브카테고리</p>
                    </div>
                    <div>
                        <p>{selectedSubCategory}</p>
                        <button onClick={deleteSubCategory}>삭제</button>
                    </div>
                </article>
            </section>


        </div>
    );
};

export default Category;